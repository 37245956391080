import { Request } from '@/http/request'

// vos用户设置--->列表 
export function getUserPrice(parameter: any) {
    return Request.axiosInstance.post('/open/voice-asr-user-config/get-user-price', parameter)
}

export function vosTotalCount(parameter: any) {
    return Request.axiosInstance.post('/open/voice-count/vos-total-count', parameter)
}
export function vosCount(parameter: any) {
    return Request.axiosInstance.post('/open/voice-count/vos-count', parameter)
}
export default { getUserPrice,vosTotalCount,vosCount }