// 数据统计
<template>
  <div>
    <div class="flex-nowrap-space-around">
      <el-card class="box-card">
        <div class="flex-nowrap-space-around out-box-card">
          <div class="in-box-card">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ (dataList.checkCharge.val).toFixed(2) }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.checkCharge.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.checkCharge.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ (dataList.checkCharge.valCharge).toFixed(2) }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>今日消费</div>
              <div>较昨日</div>
            </div>
          </div>
          <div class="in-box-card">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ (Number(dataList.durationCount.val) / 3600).toFixed(2) }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.durationCount.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.durationCount.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ (Number(dataList.durationCount.valCharge) / 3600).toFixed(2) }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>质检时长(小时)</div>
              <div>较昨日</div>
            </div>
          </div>
          <div class="in-box-card-other">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ dataList.userCount.val }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.userCount.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.userCount.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ dataList.userCount.valCharge }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>用户数量</div>
              <div>较昨日</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="flex-nowrap-space-around out-box-card">
          <div class="in-box-card">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ dataList.checkTotal.val }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.checkTotal.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.checkTotal.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ dataList.checkTotal.valCharge }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>今日质检次数</div>
              <div>较昨日</div>
            </div>
          </div>
          <div class="in-box-card">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ dataList.hitTypeCount.val }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.hitTypeCount.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.hitTypeCount.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ dataList.hitTypeCount.valCharge }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>今日命中次数</div>
              <div>较昨日</div>
            </div>
          </div>
          <div class="in-box-card-other">
            <div class="flex-nowrap-space-between">
              <div class="bold-font">{{ dataList.clientIdCount.val }}</div>
              <div class="flex-nowrap-space-around">
                <div class="img-box flex-nowrap-center-center">
                  <img class="imgs" v-if="dataList.clientIdCount.type == 0" src="../../../assets/icon/up.png" alt="">
                  <img class="imgs" v-if="dataList.clientIdCount.type == 2" src="../../../assets/icon/down.png" alt="">
                </div>
                <div>{{ dataList.clientIdCount.valCharge }}</div>
              </div>
            </div>
            <div class="flex-nowrap-space-between bottom-box">
              <div>在线IP数量</div>
              <div>较昨日</div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="table-box">
      <div>
        <el-form ref="searchForm" :inline="true" size="mini" :model="searchForm" label-position="center"
                 label-width="80px">
          <el-form-item label="用户">
            <div class="div-width">
              <el-select v-model="searchForm.uid" placeholder="请选择" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in user_list" :key="index" :label="`${item.nickname}(Id:${item.uid})`"
                           :value="item.uid">
                  <div class="flex-nowrap-space-between">
                    <div :class="item.status == 1?'no-red':''">
                      <span>{{ item.nickname }}</span>
                      <span class="ml-5">(Id:{{ item.uid }})</span>
                    </div>
                    <div v-if="item.status == 1" class="no-red">禁</div>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="IP">
            <div class="div-width">
              <el-input v-model="searchForm.ip" placeholder="IP"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="创建时间">
            <!-- <div class="div-width"> -->
            <el-date-picker v-model="searchForm.timeArr" type="datetimerange" value-format="YYYY-MM-DD"
                            :shortcuts="shortcuts" range-separator="-" start-placeholder="开始" end-placeholder="结束"/>
            <!-- </div> -->
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button @click="blick">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
            :data="tableData.firstTableData"
            stripe
            v-loading="loadingFirst"
            tooltip-effect="dark"
            style="width: 100%"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            size="mini"
            height="11vh"
            @row-click="firstRowClick"
            :header-cell-style="{background:'#e0e5ff',color:'#24252F'}">
          <el-table-column
              prop="nickName"
              label="汇总">
          </el-table-column>
          <el-table-column
              prop="clientIdCount"
              label="客户端IP总数">
          </el-table-column>
          <el-table-column
              prop="checkTotal"
              label="质检条数">
          </el-table-column>
          <el-table-column
              prop="hitTypeCount"
              label="命中次数">
          </el-table-column>
          <el-table-column
              prop="durationCount"
              label="转写时长（小时）">
            <template #default="{row}">
              {{ (row.durationCount / 3600).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="checkCharge"
              label="消费金额（元）">
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-table
            :data="tableData.secondTableData.records"
            stripe
            v-loading="loadingFirst"
            tooltip-effect="dark"
            style="width: 100%;padding-top: 15px"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            size="mini"
            height="44vh"
            @row-click="firstRowClick"
            @sort-change="formatter"
            :header-cell-style="{background:'#e0e5ff',color:'#24252F'}">
          <el-table-column
              prop="createTime"
              label="日期">
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="用户名">
          </el-table-column>
          <!-- <el-table-column
          prop="checkCharge"
          sortable
          label="单价">
          </el-table-column> -->
          <el-table-column
              prop="ip"
              label="客户端">
          </el-table-column>
          <el-table-column
              prop="checkTotal"
              sortable
              label="质检条数">
          </el-table-column>
          <el-table-column
              prop="hitTypeCount"
              sortable
              label="命中次数">
          </el-table-column>
          <el-table-column
              prop="durationCount"
              sortable
              label="转写时长（小时）">
            <template #default="scope">
              {{ (Number(scope.row.durationCount) / 3600).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="checkCharge" sortable label="消费金额（元）">
            <template #default="scope">
              {{ scope.row.checkCharge }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex-nowrap-flex-end pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                       :page-sizes="[10, 20]" :page-size="searchForm.pageSize"
                       layout="total, sizes, prev, pager, next, jumper" :total="tableData.secondTableData.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IconFont
} from '@/utils/iconfont'
import {
  ElMessage
} from 'element-plus'
import {
  getDate
} from '@/utils/timeToChoose.js'
import userts from "@/api/web/user";
import statistics from '@/api/open/voiceCheck/statistics'

export default {
  components: {
    IconFont,
  },
  data() {
    return {
      timeArr: [],
      searchForm: {
        page: 1,
        pageSize: 10,
        timeArr: []
      },
      dataList: {
        checkCharge: {
          val: 0,
          type: 1,
          valCharge: 0
        },
        durationCount: {
          val: 0,
          type: 1,
          valCharge: 0
        },
        userCount: {
          val: 0,
          type: 1,
          valCharge: 0
        },
        checkTotal: {
          val: 0,
          type: 1,
          valCharge: 0
        },
        hitTypeCount: {
          val: 0,
          type: 1,
          valCharge: 0
        },
        clientIdCount: {
          val: 0,
          type: 1,
          valCharge: 0
        },
      },
      user_list: [], //用户列表
      loadingFirst: false, //主表loading
      loadingSecond: false, //子表
      tableData: {
        firstTableData: [],
        secondTableData: {
          records: [],
          total: 0,
          size: 0,
        }
      },
      currentPage: 0,
    }
  },
  created() {
    this.searchForm.timeArr = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
    this.getUserList()
    this.getVosTotalCount()
    this.getVosCount()
  },
  watch: {},
  mounted() {

  },
  methods: {
    onSearch() {
      this.getVosTotalCount()
      this.getVosCount()
    },
    // 获取用户数据
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
    formatter(column) {
      // console.log(column);
      this.searchForm.sortBy = [column.prop]
      this.searchForm.sortDesc = [column.order == 'ascending' ? false : true]
      this.getVosCount()
    },
    getVosTotalCount() {
      statistics.vosTotalCount({
        // uid: this.searchForm.uid
      }).then((res) => {
        let newJson = {}
        for (let key in res.data.todayMap) {
          // console.log(key);
          newJson[key] = {
            val: res.data.todayMap[key],
            type: this.compareTheSize(Number(res.data.todayMap[key]), Number(res.data.yesterdayMap[key])),
            valCharge: Number(res.data.todayMap[key]) - Number(res.data.yesterdayMap[key])
          }
        }
        this.dataList = newJson
      });
    },
    compareTheSize(start, end) {
      if (start != null && end != null) {
        if (start > end) {
          return 0
        } else if (start == end) {
          // console.log(start, end);
          return 1
        } else if (start < end) {
          return 2
        }
      }
    },
    blick() {
      this.searchForm = {
        page: 1,
        pageSize: 10,
        timeArr: []
      }
      this.getVosCount()
    },
    getVosCount() {
      this.searchForm.timeArr == null ? this.searchForm.timeArr = [] : ''
      this.searchForm.dateBegin = this.searchForm.timeArr[0]
      this.searchForm.dateEnd = this.searchForm.timeArr[1]
      statistics.vosCount({
        ...this.searchForm
      }).then((res) => {
        this.tableData.firstTableData = [res.data.count]
        this.tableData.secondTableData = res.data.page
      });
    },
    // 分页
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.getVosCount()
    },
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.getVosCount()
    },
  },
}
</script>

<style lang="scss" scoped>
.box-card {
  width: 48%;
}

.out-box-card {
  width: 100%;
}

.in-box-card {
  width: 33%;
  padding: 15px;
  border-right: 1px solid #DCDFE6;
}

.in-box-card-other {
  width: 33%;
  padding: 15px;
}

.bottom-box {
  margin-top: 15px;
}

.bold-font {
  font-weight: 700;
  font-size: 20px;
}

.img-box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.imgs {
  width: 16px;
  height: 16px;
}

.table-box {
  margin-top: 15px;
}

.pagination {
  margin-top: 10px;
}
</style>